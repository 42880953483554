.footer{
    background: #e9ecf0;

    .top{
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 991px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            padding: 30px 0;
        }
    }

    .navigation{
        &-title{
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 35px 0;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                margin-bottom: 35px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }
                a{
                    font-size: 18px;
                }
            }
        }
    }

    .footer-form{
        width: 100%;
        max-width: 360px;
        @media screen and (max-width: 991px) {
           max-width: 100%;
        }
        form{
            display: flex;
            flex-direction: column;
            label{
                margin-bottom: 25px;
            }
            input{
                width: 100%;
                height: 45px;
                padding: 10px 16px;
                font-size: 18px;
                border: none;
                border-radius: 5px;
                border: 1px solid #d9dce1;
                &:focus{
                    outline: none;
                }
                &::placeholder {
                    color: #6a737d;
                    opacity: 1;
                }
                
                &:-ms-input-placeholder {
                    color: #6a737d;
                }
                
                &::-ms-input-placeholder { 
                    color: #6a737d;
                }
            }

            .button{
                box-shadow: 5px 4px 14px #888;
                min-width: 165px;
            }
        }

        .form-title{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 35px;
        }
    }

    .bottom{
        padding: 35px 0;
        background: #d9dce1;
    }

    .copy{
        text-align: center;
        margin: 0;
        padding: 0;
    }
    
}