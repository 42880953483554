$width: 1140px;

$primary-color: #3e5eea;
$error: #ff0000;
$success: #13c132;
$primary-color-dark: #002ae4;
$text-color: #192225;
$action-color: #2fcb00;
$action-color-hover: #002ae4;
$action-text-color: #fff;
$second-color: #f5f5f5;
$text-light: #f5f5f5;
$base-white: #ffffff;

$fontOxy: 'Oxygen', sans-serif;
$fontPop: 'Poppins', sans-serif;