.widget{
    padding: 60px 0;
    @media screen and (max-width: 991px) {
        padding: 40px 0;
    }
    &_title{
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        font-family: $fontOxy;
        margin: 0 0 45px 0;
        @media screen and (max-width: 991px) {
            font-size: 24px;
            margin: 0 0 25px 0;
        }
        &.white{
            color: #fff;
        }
    }

    .sub-title_text{
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        @media screen and (max-width: 991px) {
            font-size: 16px;
        }
        &.white{
            color: #fff;
        }
    }
}