.simple-text{
    .check-list{
        display: flex;
        flex-wrap: wrap;
        margin: 80px -25px 0;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            margin: 40px 0 0;
        }
        .item{
            width: 100%;
            max-width: calc(100% / 3 - 50px);
            margin: 0 25px;
            padding: 0 0 0 65px;
            background: url(../img/tick.svg) 0px 0px no-repeat;
            background-size: 55px;
            font-size: 20px;

            display: flex;
            align-items: center;

            @media screen and (max-width: 991px) {
                max-width: 100%;
                margin: 0 0 20px;
                font-size: 16px;
                min-height: 56px;
                br{
                    display: none;
                }
            }
        }

    }
}