.choose{
    .list{
        display: flex;
        justify-content: center;
        margin: 60px -15px 0;
        @media screen and (max-width: 991px) {
            margin: 40px -15px 0;
        }
        @media screen and (max-width: 767px) {
            margin: 40px 0 0;
            box-shadow: 0px 0px 20px #eee;
        }
        &--item{
            width: 100%;
            max-width: calc(100% / 3 - 30px);
            margin: 0 15px;
            padding: 40px 40px 60px;
            box-shadow: 0px 0px 20px #eee;
            transition: all linear .2s;
            @media screen and (max-width: 991px) {
                max-width: calc(100% / 3 - 16px);
                margin: 0 8px;
                padding: 25px 10px 30px;
            }
            @media screen and (max-width: 767px) {
                margin: 10px;
                box-shadow: none;
            }
            &:hover{
                transition: all linear .2s;
                box-shadow: 0px 0px 20px #aaa;
            }

            &_icon{
                text-align: center;
                margin-bottom: 25px;
                svg{
                    width: 80px;
                    height: 87px;
                }
            }

            &_title{
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 25px;
                font-family: $fontOxy;
                @media screen and (max-width: 991px) {
                    font-size: 18px;
                }
            }

            &_text{
                font-size: 18px;
                text-align: center;
                @media screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }


        }
    }

    .slick-arrow{
        width: 24px;
        height: 42px;
        position: absolute;
        top: calc(50% - 12px);
        z-index: 10;
        opacity: 0.4;
        &.arrow-prev{
            background: url(../img/arrow=grey-left.png);
            background-size: 100%;
            left: 3px;
        }
        &.arrow-next{
            background: url(../img/arrow=grey-right.png);
            background-size: 100%;
            right: 3px;
        }
    }
    

    .wrap-cta{
        text-align: center;
        margin-top: 60px;
        @media screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }
}