.steps{
    background: url(../img/bg_steps_banner.jpg) 0px 0px no-repeat;
    background-size: cover;
    padding-bottom: 80px;

    &-list{
        padding: 0 0 75px 0;
        position: relative;

        display: flex;
        margin: 65px -15px 0;
        list-style: none;
        counter-reset: section;
        z-index: 1;
        @media screen and (max-width: 767px) {
            margin: 40px -20px 0;
            padding: 0;
        }
        .item{
            width: 100%;
            //background: #fff;
            max-width: calc(100% / 4 - 30px);
            margin: 0 15px;
            text-align: center;
            position: relative;
            
            &-inner{
                background: #fff;
                padding: 55px 20px;
                
                margin: 0 auto;
                @media screen and (max-width: 767px) {
                    max-width: 250px;
                    padding: 38px 15px;
                }
            }
            @media screen and (max-width: 991px) {
                max-width: calc(100% / 4 - 20px);
                margin: 0 10px;
            }
            @media screen and (max-width: 767px) {
                //max-width: 250px;
                max-width: 100%;
                margin: 0 0 75px;
                
            }
            
    
            &_icon{
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                font-family: $fontOxy;
                font-size: 24px;
                font-weight: 600;
                padding: 0;
                margin: 20px 0 0 0;
                @media screen and (max-width: 991px) {
                    font-size: 21px;
                }
            }

            &:after{
                counter-increment: section;                 
                content: counter(section);
                position: absolute;
                bottom: -94px;
                left: 50%;
                transform: translateX(-50%);
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background: #294bde;
                color: #fff;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
                font-weight: 600;
                @media screen and (max-width: 767px) {
                    bottom: -63px;
                }
                
            }


            
        }

        .slick-arrow{
            width: 24px;
            height: 42px;
            position: absolute;
            top: calc(50% - 55px);
            z-index: 10;
            opacity: 0.4;
            &.arrow-prev{
                background: url(../img/arrow=white-left.png);
                background-size: 100%;
                left: 18px;
            }
            &.arrow-next{
                background: url(../img/arrow=white-right.png);
                background-size: 100%;
                right: 18px;
            }
        }
    }

    .line{
        background: #fff;
        position: relative;
        height: 2px;
        margin: 0 auto;
        width: calc(100% - 30px);
        z-index: 0;
        @media screen and (max-width: 767px) {
            margin: 0 -40px;
            width: calc(100% + 80px);
            margin-top: -33px;
        }

    
        &:before, &::after{
            content: '';
            position: absolute;
            top: -7px;
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background: #fff;
        }
        &:before{
            left: 0;
        }
        &:after{
            right: 0;
        }
    }
}