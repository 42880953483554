.inner-banner{
    background: $primary-color;
    padding: 30px 0;

    .inner-title{
        font-size: 40px;
        font-family: $fontOxy;
        font-weight: 600;
        color: #fff;
        @media screen and (max-width: 991px) {
            font-size: 24px;
        }
    }
}


.inner-text-blc{
    font-size: 18px;
    p{
        margin: 0 0 50px;
        @media screen and (max-width: 991px) {
            font-size: 16px;
            margin: 0 0 25px;
        }
        
    }
    a{
        color: $primary-color;
        &:hover{
            color: $action-color;
        }
    }
    ul{
        margin-bottom: 50px;
    }
    ol{
        li{
            margin-bottom: 15px;
        }
    }
    h3{
        font-weight: 600;
        font-size: 24px;
        margin: 0 0 50px;
        @media screen and (max-width: 991px) {
            font-size: 18px;
            margin: 0 0 25px;
        }
    }

}